/* eslint-disable react-refresh/only-export-components */ // FIXME
import { AppLink } from "src/ui/AppLink/AppLink"
import { allThemeColorsObject, Theme, theme, ThemeColorType } from "src/ui/themes/theme"
import styled, { css } from "styled-components"
import { Typography } from "antd"
import { LinkProps } from "antd/lib/typography/Link"
import _ from "lodash-es"

/*
These Text components match our style guide at https://www.figma.com/design/qAgFR1xUVtUAosF6LQv33f/GX-Cloud-Design-System?node-id=6748-144920&t=T0rGoUTJeHMtahIn-1
Do not edit or add to this list without working with Design to update the style guide
 */

export const defaultTextColor = (theme: Theme) => theme.colors.neutralColorPalette.blacks.colorText

export function getTextColor(fontColor: ThemeColorType | undefined): string | undefined {
  if (fontColor && _.has(allThemeColorsObject, fontColor)) {
    return allThemeColorsObject[fontColor] as string
  }
  return undefined
}

export const Heading1 = styled.div<{ $color?: ThemeColorType }>`
  ${({ theme, $color }) => css`
    color: ${getTextColor($color) ?? defaultTextColor(theme)};
    font-family: ${theme.typography.fonts.montserrat};
    font-weight: ${theme.typography.fontWeight.semibold};
    font-size: ${theme.typography.fontSize.large};
    line-height: ${theme.typography.lineHeight.large};
  `}
`

export const Heading2 = styled.div<{ $color?: ThemeColorType }>`
  ${({ theme, $color }) => css`
    color: ${getTextColor($color) ?? defaultTextColor(theme)};
    font-family: ${theme.typography.fonts.openSans};
    font-weight: ${theme.typography.fontWeight.semibold};
    font-size: ${theme.typography.fontSize.large};
    line-height: ${theme.typography.lineHeight.large};
  `}
`

export const heading3CSSProperties = {
  fontFamily: theme.typography.fonts.openSans,
  fontSize: theme.typography.fontSize.medium,
  lineHeight: theme.typography.lineHeight.large,
  fontWeight: theme.typography.fontWeight.semibold,
}
export const Heading3 = styled.div<{ $color?: ThemeColorType }>`
  ${({ theme, $color }) => css`
    color: ${getTextColor($color) ?? defaultTextColor(theme)};
    ${heading3CSSProperties};
  `}
`

export const LG = styled.span<{ $color?: ThemeColorType }>`
  ${({ theme, $color }) => css`
    color: ${getTextColor($color) ?? defaultTextColor(theme)};
    font-family: ${theme.typography.fonts.openSans};
    font-weight: ${theme.typography.fontWeight.regular};
    font-size: ${theme.typography.fontSize.medium};
    line-height: ${theme.typography.lineHeight.large};
  `}
`

export const BodyStrong = styled.span<{ $color?: ThemeColorType }>`
  ${({ theme, $color }) => css`
    color: ${getTextColor($color) ?? defaultTextColor(theme)};
    font-family: ${theme.typography.fonts.openSans};
    font-weight: ${theme.typography.fontWeight.semibold};
    font-size: ${theme.typography.fontSize.small};
    line-height: ${theme.typography.lineHeight.medium};
  `}
`

export const BodyNormal = styled.span<{ $color?: ThemeColorType } & { $pointer?: true }>`
  ${({ theme, $color, $pointer }) => css`
    color: ${getTextColor($color) ?? defaultTextColor(theme)};
    font-family: ${theme.typography.fonts.openSans};
    font-weight: 400;
    font-size: ${theme.typography.fontSize.small};
    line-height: ${theme.typography.lineHeight.medium};
    margin: 0;
    ${$pointer ? "cursor: pointer;" : ""}
  `}
`
export const DescriptionStrong = styled.span<{ $color?: ThemeColorType }>`
  ${({ theme, $color }) => css`
    color: ${getTextColor($color) ?? defaultTextColor(theme)};
    font-family: ${theme.typography.fonts.openSans};
    font-weight: ${theme.typography.fontWeight.semibold};
    font-size: ${theme.typography.fontSize.extraSmall};
    line-height: ${theme.typography.lineHeight.small};
  `}
`

export const DescriptionNormal = styled.div<{ $color?: ThemeColorType }>`
  ${({ theme, $color }) => css`
    color: ${getTextColor($color) ?? theme.colors.neutralColorPalette.blacks.colorTextTertiary};
    font-family: ${theme.typography.fonts.openSans};
    font-weight: 400;
    font-size: ${theme.typography.fontSize.extraSmall};
    line-height: ${theme.typography.lineHeight.small};
  `}
`

export const Code = styled.div<{ $color?: ThemeColorType }>`
  ${({ theme, $color }) => css`
    color: ${getTextColor($color) ?? defaultTextColor(theme)};
    font-family: ${theme.typography.fonts.code};
    font-size: ${theme.typography.fontSize.small};
    font-weight: 400;
    line-height: ${theme.typography.lineHeight.medium};
    text-align: left;
  `}
`

// TODO: simplify AppLink so that it's not so complex or use Typography.Link instead and remove LabelLink2
export const Link = styled(AppLink)<{ $color?: ThemeColorType }>`
  &.ant-typography,
  .ant-typography a {
    ${({ theme, $color }) => css`
      color: ${getTextColor($color) ?? theme.colors.primaryColors.gxAccentMedium};
      font-family: ${theme.typography.fonts.link};
      text-decoration: underline;
      font-size: ${theme.typography.fontSize.small};
      font-weight: ${theme.typography.fontWeight.semibold};
      line-height: ${theme.typography.lineHeight.large};
      text-align: left;

      &:hover,
      &:active {
        text-decoration: underline;
      }
    `}
  }
`

// use this simplified component for external links (instead of LabelLink)
export function LabelLink2({ children, ...props }: React.PropsWithChildren<LinkProps>) {
  return (
    <Typography.Link
      style={{
        textDecoration: "underline",
        fontSize: theme.typography.fontSize.small,
        lineHeight: theme.typography.lineHeight.large,
        fontWeight: theme.typography.fontWeight.regular,
      }}
      {...props}
    >
      {children}
    </Typography.Link>
  )
}

export const DescriptionUppercase = styled.div<{ $color?: ThemeColorType }>`
  ${({ theme, $color }) => css`
    color: ${getTextColor($color) ?? theme.colors.neutralColorPalette.blacks.colorTextTertiary};
    font-family: ${theme.typography.fonts.openSans};
    font-size: ${theme.typography.fontSize.extraSmall};
    font-weight: ${theme.typography.fontWeight.regular};
    line-height: 16.34px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  `}
`

export const DescriptionItalic = styled.div<{ $color?: ThemeColorType }>`
  ${({ theme, $color }) => css`
    color: ${getTextColor($color) ?? theme.colors.neutralColorPalette.blacks.colorTextTertiary};
    font-family: ${theme.typography.fonts.openSans};
    font-size: ${theme.typography.fontSize.extraSmall};
    font-style: italic;
    font-weight: ${theme.typography.fontWeight.regular};
    line-height: ${theme.typography.lineHeight.small};
    text-align: left;
  `}
`
