import { ThemeConfig as AntdThemeConfig, theme as antdTheme } from "antd"
import { flattenDeepObject } from "src/common/utils/flattenDeepObject.ts"

// Each color key must be unique in order to pass the $color prop to the Text components
const colors = {
  neutralColorPalette: {
    blacks: {
      black: "#000000" as const,
      colorText: "rgba(0,0,0,0.88)" as const, // Uses: TextHeading, IconHover   //Hex equivalent: #000000E0
      colorTextSecondary: "rgba(0,0,0,0.65)" as const, // Uses: TextLabel   //Hex equivalent: #000000A6
      colorTextTertiary: "rgba(0,0,0,0.45)" as const, // Uses: TextDescription, IconDefault   //Hex equivalent: #00000073
      colorTextQuaternary: "rgba(0,0,0,0.25)" as const, // Uses: TextPlaceholder, TextDisabled   //Hex equivalent: #00000040

      colorFill: "rgba(0,0,0,0.15)" as const, // Uses: ContentHover, BgTextActive   //Hex equivalent: #00000026
      colorFillSecondary: "rgba(0,0,0,0.06)" as const, // Uses: FillContent, BgTextHover   //Hex equivalent: #0000000F
      colorFillTertiary: "rgba(0,0,0,0.04)" as const, // Uses: BgContainerDisabled   //Hex equivalent: #0000000A
      colorFillQuaternary: "rgba(0,0,0,0.02)" as const, // Uses: TableHeading   //Hex equivalent: #00000005
    },
    whites: {
      white: "#ffffff" as const,
      colorTextDark: "rgba(255,255,255,0.85)" as const, // Uses: TextHeading, IconHover on Dark Bg   //Hex equivalent: #FFFFFFD9
      colorTextSecondaryDark: "rgba(255,255,255,0.65)" as const, // Uses: TextLabel on Dark Bg   //Hex equivalent: #FFFFFFA6
      colorTextTertiaryDark: "rgba(255,255,255,0.45)" as const, // Uses: TextDescription, IconDefault on Dark Bg   //Hex equivalent: #FFFFFF73
      colorTextQuarternaryDark: "rgba(255,255,255,0.25)" as const, // Uses: TextPlaceholder, TextDisabled on Dark Bg   //Hex equivalent: #FFFFFF40

      colorFillDark: "rgba(255,255,255,0.18)" as const, // Uses: ContentHover, BgTextActive on Dark Bg   //Hex equivalent: #FFFFFF26
      colorFillSecondaryDark: "rgba(255,255,255,0.12)" as const, // Uses: FillContent, BgTextHover on Dark Bg   //Hex equivalent: #FFFFFF1F
      colorFillTertiaryDark: "rgba(255,255,255,0.08)" as const, // Uses: BgContainerDisabled on Dark Bg   //Hex equivalent: #FFFFFF14
      colorFillQuarternaryDark: "rgba(255,255,255,0.04)" as const, // Uses: TableHeading on Dark Bg   //Hex equivalent: #FFFFFF0A
    },
    backgroundsAndBorders: {
      gxBgPrimary: "#F4F4F7" as const,
      gxBgSecondary: "#FAFAFA" as const,
      gxSurfaceSecondary: "#F9F9F9" as const,
      gxSurfaceTertiary: "#212121" as const,
      gxBorder: "#D9D9D9" as const,
    },
  },
  primaryColors: {
    gxPrimaryDark: "#2F2F2F" as const,
    gxPrimaryMedium: "#404041" as const,
    gxPrimaryLight: "#6D6E70" as const,

    gxAccentDark: "#DF570E" as const,
    gxAccentMedium: "#FF6310" as const,
    gxAccentBackground: "#FFF0E9" as const,

    gxSubMenuItem: "#8C8C8C" as const,
    gxMenuToggleIcon: "#E6E7E8" as const,
    gxBtnPrimaryActiveBoxShadow: "#BFBFBF" as const,
  },

  success: {
    gxSuccess: "#109910" as const,
    gxSuccessLight: "#5ECC30" as const, // Used for success color on dark background.
    gxSuccessBackground: "#F0FFF0" as const,
  },

  warning: {
    colorWarning: "#FAAD14" as const,
    colorWarningLight: "#FFD666" as const,
    colorWarningBg: "#FFFBE6" as const,
  },

  error: {
    gxError: "#D62828" as const,
    gxErrorLight: "#FA282F" as const, // Used for error color on dark background.
    gxErrorBackground: "#FCEEEE" as const,
  },

  status: {
    gxBadgeActive: "#5ECC30" as const,
    gxBadgeInactive: "#FA282F" as const,
    gxBadgeInitializing: "#F3C62D" as const,
  },

  info: "#1677FF" as const,
} as const

export const allThemeColorsObject = flattenDeepObject(colors)

// This creates a type from the colors object
type NestedKeys<T> = T extends object
  ? {
      [K in keyof T]: K extends string ? (T[K] extends object ? `${NestedKeys<T[K]>}` : K) : never
    }[keyof T]
  : never
export type ThemeColorType = NestedKeys<typeof colors>

const spacing = {
  scale: {
    xxxs: "8px" as const,
    xxs: "16px" as const,
    xs: "24px" as const,
    s: "32px" as const,
    m: "40px" as const,
    l: "48px" as const,
    xl: "56px" as const,
    xxl: "64px" as const,
    xxxl: "72px" as const,
  },
  vertical: {
    xxxs: "4px" as const,
    xxs: "8px" as const,
    xs: "16px" as const,
    s: "24px" as const,
    m: "32px" as const,
    l: "40px" as const,
    xl: "56px" as const,
    xxl: "64px" as const,
    xxxl: "80px" as const,
  },
  horizontal: {
    xxxs: "4px" as const,
    xxs: "8px" as const,
    xs: "16px" as const,
    s: "24px" as const,
    m: "32px" as const,
    l: "40px" as const,
    xl: "56px" as const,
    xxl: "64px" as const,
    xxxl: "80px" as const,
    xxxxl: "130px" as const,
  },
  cornerRadius: {
    none: "0px" as const,
    xsmall: "2px" as const,
    small: "4px" as const,
    medium: "8px" as const,
    large: "16px" as const,
    circle: "50%" as const,
  },
  elevation: {
    s: {
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.04), 0px 4px 20px 4px rgba(0, 0, 0, 0.04)",
    } as const,
    m: {
      boxShadow: "0px 6px 24px rgba(0, 0, 0, 0.04), 0px 12px 60px 12px rgba(0, 0, 0, 0.04)",
    } as const,
    l: {
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.04), 0px 4px 20px 4px rgba(0, 0, 0, 0.04)",
    } as const,
  },
}

const typography = {
  fonts: {
    openSans: "'Open Sans', sans-serif" as const,
    montserrat: "'Montserrat', sans-serif" as const,
    code: "Roboto Mono" as const,
    link: "Source Sans Pro" as const,
  },
  fontSize: {
    extraSmall: "12px" as const,
    small: "14px" as const,
    medium: "16px" as const,
    large: "20px" as const,
  },
  fontWeight: {
    regular: 400 as const,
    semibold: 600 as const,
  },
  lineHeight: {
    small: "20px" as const,
    medium: "22px" as const,
    large: "24px" as const,
  },
}

export const transitions = {
  color: "0.3s cubic-bezier(0.645, 0.045, 0.355, 1)",
}

export const theme = {
  colors,
  spacing,
  typography,
  transitions,
}

export type Theme = typeof theme

export const customAntdTheme: AntdThemeConfig = {
  algorithm: antdTheme.defaultAlgorithm,
  token: {
    colorPrimary: theme.colors.primaryColors.gxPrimaryMedium,
    fontFamily: theme.typography.fonts.openSans,
  },
  components: {
    Button: {
      colorLink: theme.colors.primaryColors.gxPrimaryDark,
      colorLinkActive: theme.colors.primaryColors.gxPrimaryDark,
      colorLinkHover: theme.colors.primaryColors.gxAccentMedium,
      colorPrimary: theme.colors.primaryColors.gxPrimaryDark,
      colorPrimaryActive: theme.colors.primaryColors.gxAccentMedium,
      colorPrimaryHover: theme.colors.primaryColors.gxAccentMedium,
    },
    Checkbox: {
      colorPrimary: theme.colors.primaryColors.gxAccentMedium,
    },
    Descriptions: {
      itemPaddingBottom: 3,
    },
    Form: {
      marginLG: 16,
      verticalLabelPadding: 0,
    },
    Input: {
      colorPrimaryHover: theme.colors.primaryColors.gxPrimaryDark,
      colorPrimaryBorderHover: theme.colors.primaryColors.gxPrimaryDark,
    },
    Layout: {
      headerBg: theme.colors.neutralColorPalette.whites.white,
      bodyBg: theme.colors.neutralColorPalette.whites.white,
      triggerBg: theme.colors.primaryColors.gxPrimaryDark,
    },
    List: {
      headerBg: theme.colors.neutralColorPalette.blacks.colorFillQuaternary,
      colorSplit: theme.colors.neutralColorPalette.backgroundsAndBorders.gxBorder,
      colorText: theme.colors.neutralColorPalette.blacks.colorText,
    },
    Modal: {
      paddingContentVertical: 16,
      colorBgMask: "rgba(0, 0, 0, 0.8)",
    },
    Radio: {
      colorPrimary: theme.colors.primaryColors.gxAccentMedium,
      buttonCheckedColorDisabled: theme.colors.neutralColorPalette.blacks.colorTextSecondary,
      buttonCheckedBgDisabled: theme.colors.neutralColorPalette.blacks.colorFillQuaternary,
      buttonSolidCheckedBg: theme.colors.primaryColors.gxPrimaryMedium,
      buttonSolidCheckedHoverBg: theme.colors.primaryColors.gxAccentMedium,
    },
    Tabs: {
      inkBarColor: theme.colors.primaryColors.gxAccentMedium,
      itemColor: theme.colors.neutralColorPalette.blacks.colorTextSecondary,
      itemActiveColor: theme.colors.neutralColorPalette.blacks.colorText,
      itemHoverColor: theme.colors.neutralColorPalette.blacks.colorText,
    },
    Typography: {
      colorLink: theme.colors.neutralColorPalette.blacks.colorText,
      colorLinkHover: theme.colors.primaryColors.gxAccentMedium,
      colorLinkActive: theme.colors.primaryColors.gxAccentMedium,
      colorTextHeading: theme.colors.neutralColorPalette.blacks.colorText,
    },
  },
}
